import axios from "@/plugins/axios.js";

// 评价管理列表
export function evaluationListApi(params) {
  return axios.get("/course/evaluate/school/tenant/list", { params });
}

// 评价详情 课程信息
export function evaluationDetailInfoApi(id) {
  return axios.get(`/course/evaluate/info/${id}`);
}

// 评价详情 课程评价列表
export function evaluationDetailListApi(params) {
  return axios.get("/course/evaluate/info/list", { params });
}

// 评价 学年下拉
export function evaluationYearApi(tenantId) {
  return axios.get(`/course/evaluate/school/year/list/${tenantId}`);
}

// 评价 科目下拉
export function evaluationSubjectApi(tenantId) {
  return axios.get(`/course/evaluate/subject/list/${tenantId}`);
}

// 评价 类别下拉
export function evaluationCategoryApi(tenantId) {
  return axios.get(`/course/evaluate/category/list/${tenantId}`);
}

// 评价 列表内的全部班级
export function evaluationClassApi(tenantId, courseInfoId) {
  return axios.get(
    `/course/evaluate/course/class/list/${tenantId}/${courseInfoId}`
  );
}
