<template>
  <div class="evaluation-manage">
    <div class="syncedTip">
      {{ $t("el.planCourse.updateMidnight") }}
    </div>
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      noEmptyText="el.common.noData"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="handleGoDetail(scope.row)"
          >{{ $t("el.common.showDetail") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  evaluationCategoryApi,
  evaluationListApi,
  evaluationSubjectApi,
  evaluationYearApi,
} from "@/api/manage/evaluation";
import { handparams } from "@/utils";

export default {
  computed: {
    ...mapGetters({
      termOptionsOrigin: "termOptions",
      schoolYearOptionsOrigin: "schoolYearOptions",
    }),
    ...mapState({
      // 当前学校租户下的年级
      gradeOptions(state) {
        return state.dict.TENANT_GRADE.map((item) => ({
          label: item.dictionaryText,
          value: item.dictionaryKey,
        }));
      },
      tenantId: (state) => state.schoolCode,
    }),
    // 学期下拉
    termOptions() {
      return [{ value: "", label: "全部" }, ...this.termOptionsOrigin];
    },
    // 学年下拉
    schoolYearOptions() {
      return [{ value: "", label: "全部" }, ...this.schoolYearOptionsOrigin];
    },
    // 筛选项字段
    formItems() {
      return [
        // 开课学年
        {
          itemType: "select",
          prop: "schoolYearId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.openingAcademicYear"),
          clearable: true,
          options: this.schoolYearOptions,
        },
        // 开课学期
        {
          itemType: "select",
          prop: "termId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.openingSemester"),
          clearable: true,
          options: this.termOptions,
        },
        // 课程名称
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),
          clearable: true,
        },
        // 所属机构
        {
          itemType: "input",
          prop: "orgName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.affiliation"),
          clearable: true,
        },
        // 课程来源
        {
          itemType: "select",
          prop: "sourceType",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseSource"),
          clearable: true,
          options: [
            { value: 4, label: "机构" },
            { value: 3, label: "区域" },
          ],
        },
        {
          itemType: "select",
          prop: "suitGrade",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.applyGrade"),
          clearable: true,
          options: this.gradeOptions,
        },
        // 科目
        {
          itemType: "select",
          prop: "subjectId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.courseList.subject"),
          clearable: true,
          options: this.subjectOptions,
        },
        // 课程类别
        {
          itemType: "select",
          prop: "categoryId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseCategory"),
          clearable: true,
          options: this.courseCategory,
        },
      ];
    },
    // 表格项
    columns() {
      return [
        {
          label: this.$t("el.planCourse.CourseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.planCourse.affiliation"),
          prop: "courseTenantName",
        },
        {
          label: this.$t("el.planCourse.courseSource"),
          prop: "sourceType",
        },
        {
          label: this.$t("el.planCourse.applyGrade"),
          prop: "suitGrade",
        },
        {
          label: this.$t("el.courseList.subject"),
          prop: "subjectName",
        },
        {
          label: this.$t("el.planCourse.courseCategory"),
          prop: "courseType",
        },
        {
          label: this.$t("el.planCourse.evaluationTimes"),
          prop: "evaluateCount",
        },
        {
          label: this.$t("el.planCourse.courseAverageScore"),
          prop: "evaluateScore",
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
        },
      ];
    },
  },
  data() {
    return {
      subjectOptions: [], // 列表内全部科目
      courseCategory: [], // 列表内全部类别
      // schoolYearOptions: [], // 列表内的全部学年
    };
  },
  created() {
    // this.handleGetYear();
    this.handleGetSubject();
    this.handleGetCategory();
  },
  methods: {
    // 获取列表数据
    fatchMethod(params) {
      return evaluationListApi(handparams(params));
    },
    // 获取列表内的全部学年
    // handleGetYear() {
    //   evaluationYearApi(this.tenantId).then((res) => {
    //     this.schoolYearOptions = [
    //       { value: "", label: "全部" },
    //       ...(res?.data || []).map(({ id, name }) => ({
    //         value: id,
    //         label: name,
    //       })),
    //     ];
    //   });
    // },
    // 获取列表内全部科目
    handleGetSubject() {
      evaluationSubjectApi(this.tenantId).then((res) => {
        this.subjectOptions = [
          ...(res?.data || []).map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        ];
      });
    },
    // 获取列表内全部类别
    handleGetCategory() {
      evaluationCategoryApi(this.tenantId).then((res) => {
        this.courseCategory = [
          ...(res?.data || []).map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        ];
      });
    },
    // 查看详情
    handleGoDetail(row) {
      const query = { courseTenantInfoId: row.courseTenantInfoId };
      if (this.$refs.xmTable?.$refs?.form?.formData) {
        const form = this.$refs.xmTable.$refs.form.formData;
        form.schoolYearId && (query.yearId = form.schoolYearId);
        form.termId && (query.termId = form.termId);
      }
      this.$router.push({
        name: "evaluationDetail",
        query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.evaluation-manage {
  padding: 30px 50px;
  background-color: #fff;
  border-radius: 10px;
  .syncedTip {
    text-align: right;
    font-size: 14px;
    color: #807f7f;
    margin-bottom: 12px;
  }
  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
}
</style>
