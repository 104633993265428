<template>
  <div class="evaluation-detail">
    <div class="evaluation-detail-top f-c">
      <div class="f-l evaluation-detail-top-btn" @click="goBack">
        <img
          src="@/assets/images/icon/navtop_back.png"
          class="evaluation-detail-top-btnicon"
        />
        <span>{{ $t("el.common.back") }}</span>
      </div>
      <div class="f-l evaluation-detail-top-title">
        {{ $t("el.planCourse.evaluationManage") }} >
        {{ $t("el.planCourse.evaluationDetail") }}
      </div>
    </div>

    <div class="detail-info flex flex-m">
      <div style="position: relative">
        <el-image
          class="details-header-icon"
          :src="courseObj.courseCover || defaultImage"
          fit="cover"
        >
        </el-image>
      </div>
      <div class="details-course-info">
        <span class="details-course-name">{{ courseObj.courseName }}</span>
        <div class="details-course-desc">
          <span class="details-desc-item">
            {{ $t("el.planCourse.averageScore") + $t("el.common.colon") }}
            {{ $t("el.planCourse.score", [courseObj.evaluateScore]) }}
          </span>
        </div>
        <div class="details-course-desc info">
          <span class="details-desc-item">
            {{ $t("el.planCourse.affiliation") + $t("el.common.colon") }}
            {{ courseObj.courseTenantName }}
          </span>
          <span class="details-desc-item">
            {{ $t("el.planCourse.courseCategory") + $t("el.common.colon") }}
            {{ courseObj.courseType }}
          </span>
          <span class="details-desc-item">
            {{ $t("el.courseList.subject") + $t("el.common.colon") }}
            {{ courseObj.subjectName }}
          </span>
          <span class="details-desc-item">
            {{ $t("el.planCourse.applyGrade") + $t("el.common.colon") }}
            {{ courseObj.suitGrade }}
          </span>
        </div>
      </div>
    </div>

    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      noEmptyText="el.common.noData"
    >
    </xm-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  evaluationDetailInfoApi,
  evaluationDetailListApi,
  evaluationYearApi,
  evaluationClassApi,
} from "@/api/manage/evaluation";
import { handparams } from "@/utils";

export default {
  computed: {
    ...mapGetters({
      termOptionsOrigin: "termOptions",
      schoolYearOptionsOrigin: "schoolYearOptions",
    }),
    ...mapState({
      tenantId: (state) => state.schoolCode,
    }),
    // 学期下拉
    termOptions() {
      return [{ value: "", label: "全部" }, ...this.termOptionsOrigin];
    },
    // 学年下拉
    schoolYearOptions() {
      return [{ value: "", label: "全部" }, ...this.schoolYearOptionsOrigin];
    },
    // 筛选项字段
    formItems() {
      return [
        // 开课学年
        {
          itemType: "select",
          prop: "schoolYearId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.openingAcademicYear"),
          clearable: true,
          options: this.schoolYearOptions,
        },
        // 开课学期
        {
          itemType: "select",
          prop: "termId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.openingSemester"),
          clearable: true,
          options: this.termOptions,
        },
        // 学号
        {
          itemType: "input",
          prop: "studentIdentity",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.studentNumber"),
          clearable: true,
        },
        // 学生姓名
        {
          itemType: "input",
          prop: "studentName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.studentName"),
          clearable: true,
        },
        // 课程班级
        {
          itemType: "select",
          prop: "courseClassId",
          size: "small",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.courseClass"),
          clearable: true,
          options: this.courseClassOptions,
        },
        // 评价时间
        {
          itemType: "daterange",
          prop: "_evaluationTime",
          prop1: "evaluateStartTime",
          prop2: "evaluateEndTime",
          size: "small",
          label: this.$t("el.planCourse.evaluationTime"),
          "start-placeholder": this.$t("el.common.startTime"),
          "end-placeholder": this.$t("el.common.endTime"),
          "value-format": "yyyy-MM-dd",
          clearable: true,
        },
      ];
    },
    // 表格列
    columns() {
      return [
        {
          label: this.$t("el.planCourse.openingAcademicYear"),
          prop: "courseSchoolYear",
        },
        {
          label: this.$t("el.planCourse.openingSemester"),
          prop: "courseTerm",
        },
        {
          label: this.$t("el.courseClassManage.studentNumber"),
          prop: "studentIdentity",
        },
        {
          label: this.$t("el.courseClassManage.studentName"),
          prop: "studentName",
        },
        {
          label: this.$t("el.planCourse.courseClass"),
          prop: "classInfo",
        },
        {
          label: this.$t("el.planCourse.appraiser"),
          prop: "creator",
        },
        {
          label: this.$t("el.forgotPwd.MobileNumber"),
          prop: "creatorPhone",
        },
        {
          label: this.$t("el.planCourse.evaluationTime"),
          prop: "createTime",
        },
        {
          label: this.$t("el.planCourse.courseRating"),
          prop: "score",
        },
        {
          label: this.$t("el.planCourse.evaluationContent"),
          prop: "content",
          showtooltip: "false",
          "class-name": "content-cell-wrap",
        },
      ];
    },
  },
  data() {
    return {
      courseTenantInfoId: "",
      defaultImage: require("@/assets/images/default-image.png"), // 默认封面
      courseObj: {},
      courseClassOptions: [], // 课程班级下拉项
      // schoolYearOptions: [],
    };
  },
  mounted() {
    this.courseTenantInfoId = this.$route.query.courseTenantInfoId;
    this.handleGetCourseDetail();
    // this.handleGetYear();
    this.handleGetClass();
    if (this.$refs.xmTable?.$refs?.form?.formData) {
      this.$refs.xmTable.setFormValue(
        "schoolYearId",
        Number(this.$route.query.yearId) || ""
      );
      this.$refs.xmTable.setFormValue(
        "termId",
        Number(this.$route.query.termId) || ""
      );
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 获取课程详情数据
    handleGetCourseDetail() {
      evaluationDetailInfoApi(this.courseTenantInfoId).then((res) => {
        this.courseObj = res?.data || {};
      });
    },
    // 获取列表数据
    fatchMethod(params) {
      const data = handparams({
        ...params,
        courseTenantInfoId: this.$route.query.courseTenantInfoId,
      });
      delete data._evaluationTime;
      return evaluationDetailListApi(data);
    },
    // 获取列表内的全部学年
    // handleGetYear() {
    //   evaluationYearApi(this.tenantId).then((res) => {
    //     this.schoolYearOptions = [
    //       { value: "", label: "全部" },
    //       ...(res?.data || []).map(({ id, name }) => ({
    //         value: id,
    //         label: name,
    //       })),
    //     ];
    //   });
    // },
    // 获取列表内的全部班级
    handleGetClass() {
      evaluationClassApi(this.tenantId, this.courseTenantInfoId).then((res) => {
        this.courseClassOptions = [
          ...(res?.data || []).map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        ];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.evaluation-detail {
  padding: 18px 16px 50px;
  background-color: #fff;
  border-radius: 10px;
  &-top {
    height: 32px;
    line-height: 32px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
  }

  .detail-info {
    margin: 25px 0 30px;
    .details-header-icon {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      margin-left: 9px;
    }
    .details-course-info {
      margin-left: 21px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      display: flex;
      flex-direction: column;
      .details-course-name {
        font-size: 18px;
        font-weight: 500;
        color: #131313;
        margin-bottom: 20px;
      }
      .details-course-desc {
        &.info {
          display: flex;
          flex-wrap: wrap;
          .details-desc-item {
            margin-bottom: 10px;
          }
        }
        & + .details-course-desc {
          padding-top: 10px;
        }
        .details-desc-item {
          margin-right: 63px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  /deep/ .el-button {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
  /deep/ .el-button--default {
    color: #6049ff;
    background-color: #fff;
    border-color: #6049ff !important;
  }
  /deep/ .content-cell-wrap {
    .cell {
      text-overflow: unset;
      -webkit-line-clamp: unset;
      white-space: normal;
    }
  }
}
</style>
